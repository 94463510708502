import {Col, Container, Row} from "react-bootstrap";
import Wizard420Framed from "../../../assets/img/wizards/Wizard_Framed.png";

export const CharacterNFT = () => {
  return (
    <>
      <section id="character-nft" className="pt-0">
        <Container>
          <div className="section-title">
            <h2>OWN CHARACTERS</h2>
          </div>
          <Row>
            <Col lg="4">
              <div className="left-section">
                <img src={Wizard420Framed} className='img-character' alt='character-nft'/>
              </div>
            </Col>
            <Col lg="8">
              <div className="right-section">
                <div className="body">
                  There will be 7 races that exist on Planet Crypton (2 currently), each with their own collection of tokenized
                  unique characters completely modeled, rigged, and animated in Unreal Engine 5. Owners of these NFTs will have one of the two NFTs necessary
                  to play Cosmic Universe, the other being a land NFT. A Rental Program will make playing the game easily accessible to everyone.
                  <br/> <br/>
                  Every character NFT will also possess the ability to summon a Companion that will assist players on Quests.
                  as well as provide a friendly companion.
                </div>
                <div className="footer">
                  <a className="btn-test" href="https://marketplace.cosmicuniverse.io/character"> <span className="btn"> Buy Characters</span> </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
